import {
  AppBar,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// import { Link } from "react-router-dom";

import DrawerComponent from "./drawer";

import logo from "../img/kre-sans-texte.png";
import { Language } from "@mui/icons-material";

// const useStyles = makeStyles((theme) => ({
//   navlinks: { marginLeft: theme.spacing(10), display: "flex" },
//   logo: { flexGrow: "1", cursor: "pointer" },
const link = {
  textDecoration: "none",
  fontSize: "20px",
  marginLeft: "20px",
  color: "inherit",
  "&:hover": { color: "yellow", borderBottom: "1px solid yellow" }
}
// }));

const Navbar = () => {
  //   const classes = useStyles();
  const styleAtTop = { backgroundColor: "#00000000", boxShadow: "0px 0px 0px #00000000", color: "black" }
  const styleNotAtTop = { backgroundColor: "#bad7ff", color: "black" }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [navbarStyle, setNavbarStyle] = useState(styleAtTop)
  const [position, setPosition] = useState("fixed")

  const { t, i18n } = useTranslation();
  const [languageMenuRef, setLanguageMenuRef] = useState(null);


  useEffect(() => {
    window.onscroll = function () {
      if (window.pageYOffset === 0) {
        setNavbarStyle(styleAtTop)
        setPosition("fixed")

      }
      else {
        setNavbarStyle(styleNotAtTop)
        setPosition("sticky")
      }

      const event = new Event('navbarChanged')
      document.dispatchEvent(event)
    };

    return () => {
      window.onscroll = null;
    }
  }, [])





  return (
    <AppBar position={"sticky"} className="top-bar" sx={navbarStyle}>
      <Toolbar style={{ minHeight: "fit-content", padding: "8px 20px" }}>
        <a href="/" style={{ display: "flex", alignItems: "center", textDecoration: "none", color: "inherit" }}>
          <img src={logo} alt="logo" width={40} />
          <div style={{ paddingLeft: "10px" }}>
            <Typography variant="h4"  >Kreasoft</Typography>
          </div>
        </a>
        {isMobile ? (
          <div style={{ marginLeft: "auto", display: "flex" }}>
            <DrawerComponent />
          </div>
        ) : (
          <Stack direction={"row"} style={{ marginLeft: "auto", display: "flex", alignItems: "center" }} spacing={2}>
            {/* <Link to="/" style={link} >
              allo
            </Link>
            <Link to="/about" style={link}>
              bye
            </Link>
            <Link to="/contact" style={link}>
              coucou
            </Link>
            <Link to="/faq" style={link}>
              FAQ
            </Link> */}

            <Link href="/media/SOS.exe" style={link} type="download">{t("Download SOS")}</Link>
            <IconButton onClick={(e) => setLanguageMenuRef(e.currentTarget)}>
              <Language />
            </IconButton>
            <Menu
              anchorEl={languageMenuRef}
              open={Boolean(languageMenuRef)}
              onClose={() => setLanguageMenuRef(null)}
            >
              <MenuItem onClick={() => i18n.changeLanguage("fr")}>Français</MenuItem>
              <MenuItem onClick={() => i18n.changeLanguage("en")}>English</MenuItem>
            </Menu>
          </Stack>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
