import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng: "fr",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        fr: {
            translation: {

                "Download SOS": "Télécharger SOS",
                "Privacy Policy for the application Paysage par Kreasoft": "Politique de confidentialité de l'application Paysage par Kreasoft",
                "Privacy Policy": "Politique de confidentialité",
                "This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.": "Cette Politique de Confidentialité décrit nos politiques et procédures concernant la collecte, l'utilisation et la divulgation de vos informations lorsque vous utilisez le Service et vous informe de vos droits en matière de confidentialité et de la manière dont la loi vous protège.",
                "We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the": "Nous utilisons vos données personnelles pour fournir et améliorer le Service. En utilisant le Service, vous acceptez la collecte et l'utilisation d'informations conformément à cette Politique de Confidentialité. Cette Politique de Confidentialité a été créée avec l'aide de",
                "Interpretation and Definitions": "Interprétation et Définitions",
                "Interpretation": "Interprétation",
                "The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.": "Les mots dont la lettre initiale est en majuscule ont des significations définies dans les conditions suivantes. Les définitions suivantes auront la même signification qu'ils apparaissent au singulier ou au pluriel.",
                "Definitions": "Définitions",
                "For the purposes of this Privacy Policy": "Aux fins de la présente Politique de Confidentialité",
                "Account": "Compte",
                "means a unique account created for You to access our Service or parts of our Service.": "signifie un compte unique créé pour vous permettre d'accéder à notre Service ou à des parties de notre Service.",
                "Affiliate": "Affilié",
                "means an entity that controls, is controlled by or is under common control with a party, where control means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority": "signifie une entité qui contrôle, est contrôlée par ou est sous contrôle commun avec une partie, où le contrôle signifie la propriété de 50 % ou plus des actions, des intérêts ou des autres titres de participation ayant droit de vote pour l'élection des administrateurs ou d'autres autorités de gestion",
                "Application": "Application",
                "refers to Paysage par Kreasoft, the software program provided by the Company.": "fait référence à Paysage par Kreasoft, le programme logiciel fourni par la Société.",
                "Company": "Société",
                "(referred to as either \"the Company\", \"We\", \"Us\" or \"Our\" in this Agreement) refers to Kreasoft développement de solutions informatiques inc., 5532 Chemin de l'Aéroport, Valcourt, QC, CA J0E 2L0.": "(désignée sous le nom de \"la Société\", \"Nous\", \"Nous\" ou \"Notre\" dans le présent Contrat) fait référence à Kreasoft développement de solutions informatiques inc., 5532 Chemin de l'Aéroport, Valcourt, QC, CA J0E 2L0.", 
                "Country": "Pays",
                "refers to: Quebec, Canada": "fait référence à : Québec, Canada",
                "Device": "Appareil",
                "means any device that can access the Service such as a computer, a cellphone or a digital tablet": "signifie tout appareil qui peut accéder au Service tel qu'un ordinateur, un téléphone portable ou une tablette numérique",
                "Personal Data": "Données personnelles",
                "is any information that relates to an identified or identifiable individual": "est toute information qui se rapporte à un individu identifié ou identifiable",
                "Service": "Service",
                "refers to the Application.": "fait référence à l'Application.",
                "Service Provider": "Prestataire de Services",
                "means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used": "signifie toute personne physique ou morale qui traite les données pour le compte de la Société. Il s'agit de sociétés ou de personnes tierces employées par la Société pour faciliter le Service, pour fournir le Service pour le compte de la Société, pour fournir des services liés au Service ou pour aider la Société à analyser comment le Service est utilisé",
                "Usage Data": "Données d'Utilisation",
                "refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).": "fait référence aux données collectées automatiquement, générées soit par l'utilisation du Service, soit par l'infrastructure du Service elle-même (par exemple, la durée d'une visite de page).",
                "You": "Vous",
                "means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.": "signifie la personne physique accédant ou utilisant le Service, ou la société, ou toute autre entité légale au nom de laquelle cette personne physique accède ou utilise le Service, le cas échéant.",
                "Collecting and Using Your Personal Data": "Collecte et utilisation de vos données personnelles",
                "Types of Data Collected": "Types de données collectées",
                "Personal Data": "Données personnelles",
                "While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:": "Lorsque vous utilisez notre Service, nous pouvons vous demander de nous fournir certaines informations personnellement identifiables qui peuvent être utilisées pour vous contacter ou vous identifier. Les informations personnellement identifiables peuvent inclure, mais ne sont pas limitées à :",
                "Email address": "Adresse e-mail",
                "First name and last name": "Prénom et nom de famille",
                "Phone number": "Numéro de téléphone",
                "Usage Data": "Données d'utilisation",
                "Usage Data is collected automatically when using the Service.": "Les données d'utilisation sont collectées automatiquement lors de l'utilisation du Service.",
                "Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.": "Les données d'utilisation peuvent inclure des informations telles que l'adresse de protocole Internet de votre appareil (par exemple, l'adresse IP), le type de navigateur, la version du navigateur, les pages de notre Service que vous visitez, l'heure et la date de votre visite, le temps passé sur ces pages, des identifiants d'appareil uniques et d'autres données de diagnostic.",
                "When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.": "Lorsque vous accédez au Service par le biais d'un appareil mobile, nous pouvons collecter certaines informations automatiquement, y compris, mais sans s'y limiter, le type d'appareil mobile que vous utilisez, l'identifiant unique de votre appareil mobile, l'adresse IP de votre appareil mobile, votre système d'exploitation mobile, le type de navigateur Internet mobile que vous utilisez, des identifiants d'appareil uniques et d'autres données de diagnostic.",
                "We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.": "Nous pouvons également collecter des informations que votre navigateur envoie chaque fois que vous visitez notre Service ou lorsque vous accédez au Service par le biais d'un appareil mobile.",
                "Information Collected while Using the Application": "Informations collectées lors de l'utilisation de l'application",
                "While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:": "Lorsque vous utilisez notre application, afin de fournir des fonctionnalités de notre application, nous pouvons collecter, avec votre autorisation préalable :",
                "Information regarding your location": "Informations concernant votre emplacement",
                "Pictures and other information from your Device's camera": "Photos et autres informations de l'appareil photo de votre appareil",
                "We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.": "Nous utilisons ces informations pour fournir des fonctionnalités de notre Service, pour améliorer et personnaliser notre Service. Les informations peuvent être téléchargées sur les serveurs de la Société et/ou sur le serveur d'un Prestataire de Services ou elles peuvent simplement être stockées sur votre appareil.",
                "You can enable or disable access to this information at any time, through Your Device settings.": "Vous pouvez activer ou désactiver l'accès à ces informations à tout moment, via les paramètres de votre appareil.",
                "Use of Your Personal Data": "Utilisation de vos données personnelles",
                "The Company may use Personal Data for the following purposes:": "La Société peut utiliser des Données Personnelles aux fins suivantes :",
                "To provide and maintain our Service": "Pour fournir et maintenir notre Service",
                "including to monitor the usage of our Service.": "y compris pour surveiller l'utilisation de notre Service.",
                "To manage Your Account": "Pour gérer votre compte",
                "to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.": "pour gérer votre inscription en tant qu'utilisateur du Service. Les Données Personnelles que vous fournissez peuvent vous donner accès à différentes fonctionnalités du Service qui sont disponibles pour vous en tant qu'utilisateur enregistré.",
                "For the performance of a contract": "Pour l'exécution d'un contrat",
                "the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.": "le développement, la conformité et l'exécution du contrat d'achat des produits, articles ou services que vous avez achetés ou de tout autre contrat avec nous par le biais du Service.",
                "To contact You": "Pour vous contacter",
                "To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.": "Pour vous contacter par e-mail, appels téléphoniques, SMS, ou d'autres formes de communication électronique équivalentes, telles que les notifications push d'une application mobile concernant les mises à jour ou les communications informatives liées aux fonctionnalités, produits ou services contractés, y compris les mises à jour de sécurité, lorsque cela est nécessaire ou raisonnable pour leur mise en œuvre.",
                "To manage Your requests": "Pour gérer vos demandes",
                "For other purposes": "Pour d'autres finalités",
                "We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.": "Nous pouvons utiliser vos informations à d'autres fins, telles que l'analyse des données, l'identification des tendances d'utilisation, la détermination de l'efficacité de nos campagnes promotionnelles et pour évaluer et améliorer notre Service, nos produits, nos services, notre marketing et votre expérience.",
                "We may share Your personal information in the following situations:": "Nous pouvons partager vos informations personnelles dans les situations suivantes :",
                "With Service Providers": "Avec les Prestataires de Services",
                "We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.": "Nous pouvons partager vos informations personnelles avec des prestataires de services pour surveiller et analyser l'utilisation de notre Service, pour vous contacter.",
                "For Business transfers": "Pour les transferts d'entreprise",
                "We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.": "Nous pouvons partager ou transférer vos informations personnelles dans le cadre de, ou lors de négociations concernant, toute fusion, vente d'actifs de la Société, financement ou acquisition de tout ou partie de notre entreprise par une autre société.",
                "With Affiliates": "Avec les affiliés",
                "We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.": "Nous pouvons partager vos informations avec nos affiliés, auquel cas nous exigerons que ces affiliés respectent la présente Politique de Confidentialité. Les affiliés incluent notre société mère et toute autre filiale, partenaires de coentreprise ou autres sociétés que nous contrôlons ou qui sont sous contrôle commun avec nous.",
                "With Business partners": "Avec les partenaires commerciaux",
                "We may share Your information with Our business partners to offer You certain products, services or promotions.": "Nous pouvons partager vos informations avec nos partenaires commerciaux pour vous proposer certains produits, services ou promotions.",
                "With other users": "Avec d'autres utilisateurs",
                "when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.": "lorsque vous partagez des informations personnelles ou interagissez autrement dans les espaces publics avec d'autres utilisateurs, ces informations peuvent être consultées par tous les utilisateurs et peuvent être distribuées publiquement à l'extérieur.",
                "With Your consent": "Avec votre consentement",
                "We may disclose Your personal information for any other purpose with Your consent.": "Nous pouvons divulguer vos informations personnelles pour toute autre fin avec votre consentement.",
                "Retention of Your Personal Data": "Conservation de vos données personnelles",
                "The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.": "La Société ne conservera vos Données Personnelles que le temps nécessaire aux fins énoncées dans la présente Politique de Confidentialité. Nous conserverons et utiliserons vos Données Personnelles dans la mesure nécessaire pour nous conformer à nos obligations légales (par exemple, si nous sommes tenus de conserver vos données pour nous conformer aux lois applicables), résoudre les litiges et faire respecter nos accords et politiques juridiques.",
                "The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.": "La Société conservera également les Données d'Utilisation à des fins d'analyse interne. Les Données d'Utilisation sont généralement conservées pour une période plus courte, sauf lorsque ces données sont utilisées pour renforcer la sécurité ou améliorer la fonctionnalité de notre Service, ou lorsque nous sommes légalement tenus de conserver ces données pour des périodes plus longues.",
                "Transfer of Your Personal Data": "Transfert de vos données personnelles",
                "Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.": "Vos informations, y compris les Données Personnelles, sont traitées dans les bureaux d'exploitation de la Société et dans tout autre lieu où se trouvent les parties impliquées dans le traitement. Cela signifie que ces informations peuvent être transférées à — et conservées sur — des ordinateurs situés en dehors de votre État, province, pays ou autre juridiction gouvernementale où les lois sur la protection des données peuvent différer de celles de votre juridiction.",
                "Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.": "Votre consentement à la présente Politique de Confidentialité suivi de votre soumission de ces informations représente votre accord à ce transfert.",
                "The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.": "La Société prendra toutes les mesures raisonnablement nécessaires pour s'assurer que vos données sont traitées en toute sécurité et conformément à la présente Politique de Confidentialité et aucun transfert de vos Données Personnelles ne sera effectué vers une organisation ou un pays à moins qu'il n'y ait des contrôles adéquats en place, y compris la sécurité de vos données et autres informations personnelles.",
                "Disclosure of Your Personal Data": "Divulgation de vos données personnelles",
                "Business Transactions": "Transactions commerciales",
                "If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.": "Si la Société est impliquée dans une fusion, une acquisition ou une vente d'actifs, vos Données Personnelles peuvent être transférées. Nous vous informerons avant que vos Données Personnelles ne soient transférées et ne soient soumises à une Politique de Confidentialité différente.",
                "Law enforcement": "Application de la loi",
                "Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).": "Dans certaines circonstances, la Société peut être tenue de divulguer vos Données Personnelles si elle est tenue de le faire par la loi ou en réponse à des demandes valides des autorités publiques (par exemple un tribunal ou un organisme gouvernemental).",
                "Other legal requirements": "Autres exigences légales",
                "The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:": "La Société peut divulguer vos Données Personnelles de la bonne foi qu'une telle action est nécessaire pour :",
                "Comply with a legal obligation": "Se conformer à une obligation légale",
                "Protect and defend the rights or property of the Company": "Protéger et défendre les droits ou les biens de la Société",
                "Prevent or investigate possible wrongdoing in connection with the Service": "Prévenir ou enquêter sur d'éventuels actes répréhensibles en relation avec le Service",
                "Protect the personal safety of Users of the Service or the public": "Protéger la sécurité personnelle des utilisateurs du Service ou du public",
                "Protect against legal liability": "Se protéger contre la responsabilité légale",
                "Security of Your Personal Data": "Sécurité de vos données personnelles",
                "The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.": "La sécurité de vos Données Personnelles est importante pour nous, mais n'oubliez pas qu'aucune méthode de transmission sur Internet, ou méthode de stockage électronique n'est sûre à 100 %. Bien que nous nous efforcions d'utiliser des moyens commercialement acceptables pour protéger vos Données Personnelles, nous ne pouvons garantir leur sécurité absolue.",
                "Children's Privacy": "Confidentialité des enfants",
                "Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.": "Notre Service ne s'adresse à personne de moins de 13 ans. Nous ne collectons pas sciemment d'informations personnellement identifiables auprès de quiconque de moins de 13 ans. Si vous êtes un parent ou un tuteur et que vous savez que votre enfant nous a fourni des Données Personnelles, veuillez nous contacter. Si nous apprenons que nous avons collecté des Données Personnelles auprès de quiconque de moins de 13 ans sans vérification du consentement parental, nous prenons des mesures pour supprimer ces informations de nos serveurs.",
                "If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.": "Si nous devons nous appuyer sur le consentement comme base légale pour le traitement de vos informations et que votre pays exige le consentement d'un parent, nous pouvons exiger le consentement de vos parents avant de collecter et d'utiliser ces informations.",
                "Links to Other Websites": "Liens vers d'autres sites web",
                "Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.": "Notre Service peut contenir des liens vers d'autres sites web qui ne sont pas exploités par nous. Si vous cliquez sur un lien tiers, vous serez dirigé vers le site de ce tiers. Nous vous conseillons vivement de consulter la Politique de Confidentialité de chaque site que vous visitez.",
                "We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.": "Nous n'avons aucun contrôle sur le contenu, les politiques de confidentialité ou les pratiques de tout site ou service tiers.",
                "Changes to this Privacy Policy": "Modification de cette Politique de Confidentialité",
                "We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.": "Nous pouvons mettre à jour notre Politique de Confidentialité de temps à autre. Nous vous informerons de tout changement en publiant la nouvelle Politique de Confidentialité sur cette page.",
                "We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the \"Last updated\" date at the top of this Privacy Policy.": "Nous vous informerons par courrier électronique et/ou par un avis en évidence sur notre Service, avant que le changement ne devienne effectif et nous mettrons à jour la \"Date de la dernière mise à jour\" en haut de cette Politique de Confidentialité.",
                "You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.": "Il est recommandé de consulter périodiquement cette Politique de Confidentialité pour prendre connaissance de toute modification. Les modifications apportées à cette Politique de Confidentialité sont effectives lorsqu'elles sont publiées sur cette page.",
                "Contact Us": "Contactez-nous",
                "If you have any questions about this Privacy Policy, You can contact us:": "Si vous avez des questions concernant cette Politique de Confidentialité, vous pouvez nous contacter :",
                "By email:" : "Par courriel :",
                "By visiting this page on our website:" : "En visitant cette page sur notre site web :",
                "Last updated:" : "Dernière mise à jour:",
                
                "To attend and manage Your requests to Us.": "Pour répondre et gérer vos demandes à notre égard.",
                "Delete Your Personal Data": "Supprimer vos données personnelles",
                "You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.": "Vous avez le droit de supprimer ou de demander que nous vous aidions à supprimer les Données Personnelles que nous avons collectées à votre sujet.",
                "Our Service may give You the ability to delete certain information about You from within the Service.": "Notre Service peut vous donner la possibilité de supprimer certaines informations vous concernant depuis le Service.",
                "You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.": "Vous pouvez mettre à jour, modifier ou supprimer vos informations à tout moment en vous connectant à votre compte, si vous en avez un, et en visitant la section des paramètres du compte qui vous permet de gérer vos informations personnelles. Vous pouvez également nous contacter pour demander l'accès, la correction ou la suppression de toute information personnelle que vous nous avez fournie.",

                "Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so." : "Veuillez noter, cependant, que nous pouvons être amenés à conserver certaines informations lorsque nous avons une obligation légale ou une base légale pour le faire.",
                "https://kreasoft.ca#contact": "https://kreasoft.ca#contact",
            }   
        },
        en: {
            translation: {

            }
        }
    },
});

export default i18n;